import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class Extranet {
  getGroups() {
    return axios
      .get(`${config["apiUrl"]}extranet/groups`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  addGroup(bodyParms) {
    return axios
      .post(`${config["apiUrl"]}extranet/groups`, bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updategroup(groupId, bodyParms) {
    return axios
      .put(`${config["apiUrl"]}extranet/groups/${groupId}`, bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getUsers() {
    return axios
      .get(`${config["apiUrl"]}extranet/users`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  addUser(bodyParms) {
    return axios
      .post(`${config["apiUrl"]}extranet/users`, bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updateUser(userId, bodyParms) {
    return axios
      .put(`${config["apiUrl"]}extranet/users/${userId}`, bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  deleteUser(userId) {
    return axios
      .delete(`${config["apiUrl"]}extranet/users/${userId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new Extranet();
