<template>
  <div class="card p-shadow-6">
    <h1>Extranet Users</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>
    <div style="height: 65vh">
      <DataTable
        :value="usersList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="name"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Não existem utilizadores. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div
            class="table-header"
            v-if="$store.state.auth.user.isEsa || can('extranetAddUser')"
          >
            <Button icon="pi pi-plus" @click="newUser()" />
          </div>
        </template>
        <Column
          field="name"
          filterField="name"
          header="Nome"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="email"
          filterField="email"
          header="Email"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="telephone"
          filterField="telephone"
          header="Telefone"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="client.name"
          filterField="client.name"
          header="Cliente"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="group.name"
          filterField="group.name"
          header="Grupo"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              v-if="$store.state.auth.user.isEsa || can('extranetRemoveUser')"
              icon="pi pi-trash"
              class="p-button-sm p-button-rounded p-button-danger p-mr-1"
              @click="removeUser(slotProps)"
            />
            <Button
              v-if="$store.state.auth.user.isEsa || can('extranetUpdateUser')"
              icon="pi pi-pencil"
              class="p-button-sm p-button-rounded p-button-success"
              @click="updateUser(slotProps)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="userModel.showForm"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="
        (userModel.id == undefined ? 'Criar' : 'Actualizar') + ' Utilizador'
      "
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form
        name="aclCtegoryForm"
        class="p-col-12"
        @submit.prevent="saveCategory"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <span class="p-float-label">
              <dropdown
                v-model="userModel.contact_id"
                :options="contactsList"
                optionValue="id"
                dataKey="reference"
                :optionLabel="contactsListLabel"
                v-validate="'required'"
                :disabled="userModel.id != undefined"
                :filter="true"
                :showClear="true"
                :name="'contact_id'"
                v-bind:class="[
                  { 'p-invalid': errors.has('contact_id') },
                  'form-control',
                ]"
              />
              <label for="contact_id">Contacto</label>
            </span>
            <small v-if="errors.has('contact_id')" class="p-error" role="alert">
              Contacto é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <span class="p-float-label">
              <dropdown
                v-model="userModel.client_id"
                :options="clientsList"
                optionValue="id"
                dataKey="reference"
                optionLabel="name"
                v-validate="'required'"
                :filter="true"
                :showClear="true"
                :name="'client_id'"
                v-bind:class="[
                  { 'p-invalid': errors.has('client_id') },
                  'form-control',
                ]"
              />
              <label for="client_id">Cliente</label>
            </span>
            <small v-if="errors.has('client_id')" class="p-error" role="alert">
              Cliente é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <span class="p-float-label">
              <dropdown
                v-model="userModel.group_id"
                :options="groupsList"
                optionValue="id"
                dataKey="id"
                optionLabel="name"
                :filter="true"
                :showClear="true"
                :name="'group_id'"
                v-bind:class="[
                  { 'p-invalid': errors.has('group_id') },
                  'form-control',
                ]"
              />
              <label for="group_id">Grupo</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <span class="p-float-label">
              <Password v-model="userModel.password" toggleMask></Password>
              <label for="passworf">Password</label>
            </span>
            <small v-if="errors.has('passworf')" class="p-error" role="alert">
              Password é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveUser"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeUserForm"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api/";
import extranetService from "../services/extranet.service";
import clientService from "../services/clients.services";
import externalManagerService from "../services/externalManager.service";
export default {
  name: "ExtranetUsers",
  data() {
    return {
      loading: false,
      usersList: [],
      contactsList: [],
      clientsList: [],
      groupsList: [],
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        telephone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "client.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "group.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      userModel: { showForm: false },
    };
  },
  async created() {
    this.loading = true;
    this.usersList = await extranetService.getUsers();
    this.groupsList = await extranetService.getGroups();
    this.clientsList = await clientService.getAllClients();
    this.contactsList = await externalManagerService.getAll();
    this.loading = false;
  },
  methods: {
    contactsListLabel(item) {
      let label = `${item.name}`;
      if (item.email != "") {
        label += ` | ${item.email}`;
      }
      if (item.telephone != "") {
        label += ` | ${item.telephone.replace(" ", "")}`;
      }
      if (item.type != "") {
        label += ` | ${item.type}`;
      }
      return label;
    },
    closeUserForm() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.userModel = { showForm: false });
    },
    newUser() {
      return (this.userModel = {
        showForm: true,
        password: this.passwordGenerate(),
      });
    },
    removeUser(info) {
      let title = "Eliminar Utilizador";
      let message = `Tem a certeza que pretende eliminar o utilizador ${info.data.name}?`;
      this.$confirm.require({
        key: "dialogProposalDeleteNote",
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return extranetService.deleteUser(info.data.id).then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro ao eliminar o utilizador",
                life: 3000,
              });
            }
            this.usersList.splice(this.getIndex(info.data.id), 1);
            this.$toast.add({
              severity: "success",
              summary: "Utilizador Eliminado",
              detail: "O utilizador foi eliminado com sucesso",
              life: 3000,
            });
            return this.closeUserForm();
          });
        },
        reject: () => {
          return this.closeUserForm();
        },
      });
    },
    updateUser(info) {
      return (this.userModel = {
        showForm: true,
        id: info.data.id,
        index: info.index,
        client_id: `${info.data.client_id}`,
        contact_id: info.data.contact_id,
        group_id: info.data.group_id,
      });
    },
    saveUser() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        if (this.userModel.id == undefined) {
          return this.saveNewUser();
        }
        return this.saveUpdateUser();
      });
    },
    saveNewUser() {
      let bodyParams = {
        contact_id: this.userModel.contact_id,
        client_id: this.userModel.client_id,
        password: this.userModel.password,
      };
      if (this.userModel.group_id != undefined) {
        bodyParams.group_id = this.userModel.group_id;
      }
      return extranetService.addUser(bodyParams).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao adicionar o utilizador",
            life: 3000,
          });
        }
        this.usersList.push(response);
        this.$toast.add({
          severity: "success",
          summary: "Utilizador Criado",
          detail: "O utilizador foi criado com sucesso",
          life: 3000,
        });
        return this.closeUserForm();
      });
    },
    saveUpdateUser() {
      let bodyParams = {
        contact_id: this.userModel.contact_id,
        client_id: this.userModel.client_id,
        password: this.userModel.password,
      };
      if (this.userModel.group_id != undefined) {
        bodyParams.group_id = this.userModel.group_id;
      }

      if (
        this.userModel.password != undefined &&
        this.userModel.password != ""
      ) {
        bodyParams.password = this.userModel.password;
      }

      return extranetService
        .updateUser(this.userModel.id, bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao actualizar o utilizador",
              life: 3000,
            });
          }
          this.usersList.splice(this.getIndex(this.userModel.id), 1);
          this.usersList.push(response);
          this.$toast.add({
            severity: "success",
            summary: "Utilizador atualizado",
            detail: "O utilizador foi atualizado com sucesso",
            life: 3000,
          });
          return this.closeUserForm();
        });
    },
    passwordGenerate() {
      let charactersArray = "a-z,A-Z,0-9,#".split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }

      for (let i = 0; i < 12; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return password;
    },
    getIndex(id) {
      return this.usersList.findIndex((user) => user.id == id);
    },
  },
};
</script>
